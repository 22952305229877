import React from "react";
import { graphql } from "gatsby";
import AppBarPage from "../components/AppBarPage";
import { Helmet } from "react-helmet";
import { Box } from "@mui/material";
import { blueUrlColor, prodUrl, topAppBarHeight } from "../constants/BaseConstants";
import CssBaseline from "@mui/material/CssBaseline";

const defaultFontSize = 1.08; // in rem

const content = {
  color: "#333",
  maxWidth: "750px",
  margin: "auto",
  marginTop: topAppBarHeight + 40 + "px",
  marginBottom: "100px",
  paddingLeft: "14px",
  paddingRight: "14px",
  "@media(min-width: 400px)": {
    paddingLeft: "22px",
    paddingRight: "22px",
  },

  fontSize: defaultFontSize + "rem",
};

export default function PageTemplate({ data }: any): JSX.Element {
  // this prop will be injected by the GraphQL query below.
  const { markdownRemark } = data; // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark;

  const pageUrl = prodUrl + frontmatter.path;

  return (
    <Box>
      <AppBarPage title={frontmatter.title} />
      <CssBaseline />
      <Box sx={content} dangerouslySetInnerHTML={{ __html: html }} />
      <style dangerouslySetInnerHTML={{ __html: `a {text-decoration: none; color:${blueUrlColor}}` }} />
      <Helmet>
        <title>{frontmatter.helmetTitle}</title>
        <meta name="description" content={frontmatter.helmetDescription} />
        <meta name="author" content="Talal Itani" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href={pageUrl} />
      </Helmet>
    </Box>
  );
}

export const pageQuery = graphql`
  query ($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        helmetTitle
        helmetDescription
      }
    }
  }
`;
